import React, { useState } from "react"
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { Link } from "gatsby"

// Helper: Extract YouTube video ID from Shorts and standard URLs.
const getYouTubeId = (url) => {
  const shortsRegExp = /youtube\.com\/shorts\/([^\s?&]+)/;
  const shortsMatch = url.match(shortsRegExp);
  if (shortsMatch && shortsMatch[1]) return shortsMatch[1];

  const standardRegExp = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([^\s&]+)/;
  const standardMatch = url.match(standardRegExp);
  return standardMatch && standardMatch[1] ? standardMatch[1] : "";
};

const FAQs = ({ articles }) => {
  const [idx, setIdx] = useState(-1);

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: node => {
        const url = node.data.uri || "";

        // Check for YouTube Shorts URLs
        if (url.includes("youtube.com/shorts/")) {
          const videoId = getYouTubeId(url);
          if (videoId) {
            return (
              <div className="youtube-short-embed-container">
                <iframe
                  width="270"
                  height="480"
                  src={`https://www.youtube.com/embed/${videoId}?rel=0`}
                  frameBorder="0"
                  allowFullScreen
                  title="YouTube Short Video"
                />
              </div>
            );
          }
        }

        // Check for standard YouTube URLs
        if (url.includes("youtube.com/watch") || url.includes("youtu.be")) {
          const videoId = getYouTubeId(url);
          if (videoId) {
            return (
              <div className="youtube-embed-container">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}?rel=0`}
                  frameBorder="0"
                  allowFullScreen
                  title="YouTube Video"
                />
              </div>
            );
          }
        }

        // Fallback: external link or Gatsby Link.
        if (url.startsWith("https://")) {
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {node.content[0].value}
            </a>
          );
        } else {
          return <Link to={url}>{node.content[0].value}</Link>;
        }
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, description } = node.data.target || {};
        const fallbackSrc = gatsbyImageData?.images?.fallback?.src;
        if (!fallbackSrc) return null;
        return (
          <img
            src={fallbackSrc}
            alt={description || ""}
            style={{ maxWidth: "100%", height: "auto" }}
          />
        );
      },
    },
  };

  return (
    <section className="faq-sec">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>{articles.faqs.title}</h2>
            <p>{articles.faqs.content.content}</p>
          </div>
          <div className="col">
            <div className="accordion" id="accordionExample">
              {articles.faqs.faqs.map((faq, i) => (
                <div className="accordion-item" key={faq.id}>
                  <h2 className="accordion-header" id={`heading${i + 1}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${i + 1}`}
                      aria-expanded={i + 1 === idx}
                      aria-controls={`collapse${i + 1}`}
                      onClick={() => setIdx(idx === i + 1 ? -1 : i + 1)}
                    >
                      {faq.question}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12.636"
                        height="7.802"
                        viewBox="0 0 12.636 7.802"
                      >
                        <path
                          id="Icon_material-keyboard-arrow-down"
                          data-name="Icon material-keyboard-arrow-down"
                          d="M10.485,11.76l4.833,4.823,4.833-4.823,1.485,1.485-6.318,6.318L9,13.245Z"
                          transform="translate(-9 -11.76)"
                          fill="#ff0245"
                        />
                      </svg>
                    </button>
                  </h2>
                  <div
                    id={`collapse${i + 1}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${i + 1}`}
                    data-bs-parent="#accordionExample"
                    style={{ display: i + 1 === idx ? "block" : "none" }}
                  >
                    <div className="accordion-body">
                      {renderRichText(faq.theAnswer, options)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
