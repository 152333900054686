import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import FAQs from "../components/article/faqShort"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Visit from "../components/shared/visit"
import MetaCards from "../components/common/meta-cards"

export default function FinancingOptions({ data }) {
  // Destructure fields
  const {
    title,
    heroContent,
    image,
    vendor1,
    vendor1HowToApply,
    vendor2,
    vendor2HowToApply,
    vendor3,
    vendor3HowToApply,
    treatments = [],
  } = data.contentfulFinancingPage || {}

  // Log the treatments array for debugging
  console.log("TREATMENTS array:", treatments)

  // ----- HERO CONTENT (standard approach) -----
  const heroOptions = {}
  const vendor1HowToApplyOptions = {}
  const vendor2HowToApplyOptions = {}
  const vendor3HowToApplyOptions = {}

  // ----- VENDOR1 (manual approach) -----
  const vendor1Doc = vendor1 ? JSON.parse(vendor1.raw) : null
  if (vendor1Doc && vendor1Doc.content && Array.isArray(vendor1.references)) {
    vendor1Doc.content = vendor1Doc.content.map(node => {
      if (node.nodeType === "embedded-asset-block" && node.data?.target?.sys?.id) {
        const assetId = node.data.target.sys.id
        const matchedAsset = vendor1.references.find(
          ref => ref.contentful_id === assetId
        )
        if (matchedAsset) {
          node.data.target = matchedAsset
        }
      }
      return node
    })
  }

  const vendor1RenderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file, title, description } = node.data.target || {}
        if (!file?.url) return null
        return (
          <img
            src={file.url}
            alt={description || title || "Embedded Image"}
            style={{ maxWidth: "100%", height: "auto", display: "block" }}
          />
        )
      },
    },
  }

  // ----- VENDOR2 (manual approach) -----
  const vendor2Doc = vendor2 ? JSON.parse(vendor2.raw) : null
  if (vendor2Doc && vendor2Doc.content && Array.isArray(vendor2.references)) {
    vendor2Doc.content = vendor2Doc.content.map(node => {
      if (node.nodeType === "embedded-asset-block" && node.data?.target?.sys?.id) {
        const assetId = node.data.target.sys.id
        const matchedAsset = vendor2.references.find(
          ref => ref.contentful_id === assetId
        )
        if (matchedAsset) {
          node.data.target = matchedAsset
        }
      }
      return node
    })
  }

  const vendor2RenderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file, title, description } = node.data.target || {}
        if (!file?.url) return null
        return (
          <img
            src={file.url}
            alt={description || title || "Embedded Image"}
            style={{ maxWidth: "100%", height: "auto", display: "block" }}
          />
        )
      },
    },
  }

  // ----- VENDOR3 (manual approach) -----
  const vendor3Doc = vendor3 ? JSON.parse(vendor3.raw) : null
  if (vendor3Doc && vendor3Doc.content && Array.isArray(vendor3.references)) {
    vendor3Doc.content = vendor3Doc.content.map(node => {
      if (node.nodeType === "embedded-asset-block" && node.data?.target?.sys?.id) {
        const assetId = node.data.target.sys.id
        const matchedAsset = vendor3.references.find(
          ref => ref.contentful_id === assetId
        )
        if (matchedAsset) {
          node.data.target = matchedAsset
        }
      }
      return node
    })
  }

  const vendor3RenderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file, title, description } = node.data.target || {}
        if (!file?.url) return null
        return (
          <img
            src={file.url}
            alt={description || title || "Embedded Image"}
            style={{ maxWidth: "100%", height: "auto", display: "block" }}
          />
        )
      },
    },
  }

  return (
    <Layout>
        <Seo title="Financing Options" />
        <MetaCards data={data.contentfulFinancingPage.webMeta}/>

      {/* ----- Hero Section ----- */}
      <section className="financing-hero">
        <div className="container">
          <div className="financing-hero--contents">
            <h6>{title}</h6>
            {heroContent && renderRichText(heroContent, heroOptions)}
          </div>
          {image?.file?.url && <img src={image.file.url} alt="Hero" />}
        </div>
      </section>

      {/* ----- Vendor1 Section ----- */}
      <section className="vendor-sec">
        <div className="container">
          <div className="left-col">
            {vendor1Doc &&
              documentToReactComponents(vendor1Doc, vendor1RenderOptions)}
          </div>
          <div className="right-col">
            {vendor1HowToApply &&
              renderRichText(vendor1HowToApply, vendor1HowToApplyOptions)}
          </div>
        </div>
      </section>

      {/* ----- Vendor2 Section ----- */}
      <section className="vendor-sec vendor-2">
        <div className="container">
          <div className="right-col">
            {vendor2HowToApply &&
              renderRichText(vendor2HowToApply, vendor2HowToApplyOptions)}
          </div>
          <div className="left-col">
            {vendor2Doc &&
              documentToReactComponents(vendor2Doc, vendor2RenderOptions)}
          </div>
        </div>
      </section>

      {/* ----- Vendor3 Section ----- */}
      <section className="vendor-sec vendor-3">
        <div className="container">
          <div className="left-col">
            {vendor3Doc &&
              documentToReactComponents(vendor3Doc, vendor3RenderOptions)}
          </div>
          <div className="right-col">
            {vendor3HowToApply &&
              renderRichText(vendor3HowToApply, vendor3HowToApplyOptions)}
          </div>
        </div>
      </section>

      {/* ----- Treatments Section (mapped like 'appointments') ----- */}
      {treatments.length > 0 && (
        <section className="treatments-sec">
          <div className="container">
            <h2>Treatments and Procedures Covered</h2>
            <p className="lead">
                Using your CareCredit card or Scratchpay allows you to manage a broad range of veterinary expenses more comfortably. Below are examples of treatments and procedures typically eligible for coverage or financing:
            </p>
            <ul>
              {treatments.map((treatment, index) => {
                return (
                    <li key={index}>
                    <img src={treatment.image.file.url} />
                    <h3
                        dangerouslySetInnerHTML={{
                        __html: treatment.label
                        }}
                    />
                    <p>{treatment.shortDescription}</p>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
          )}
          <div className="">
              <FAQs articles={data.contentfulFinancingPage} />
              <Visit data={data.contentfulFinancingPage.scheduleAVisit} />
          </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulFinancingPage {
    webMeta {
        pageTitle
        pageMetaDescription
        pageMetaKeywords
      }
      title
      heroContent {
        raw
      }
      image {
        file {
          url
        }
      }

      vendor1 {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            file {
              url
            }
          }
        }
      }
      vendor1HowToApply {
        raw
      }

      vendor2 {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            file {
              url
            }
          }
        }
      }
      vendor2HowToApply {
        raw
      }

      vendor3 {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            file {
              url
            }
          }
        }
      }
      vendor3HowToApply {
        raw
      }

        treatments {
            label
            shortDescription
            image {
            file {
            url
            }
        }
      }
     faqs {
        title
        content {
          content
        }
        faqs {
          id
          question
          theAnswer {
            raw
          }
        }
      }
      scheduleAVisit {
        image1 {
          file {
            url
          }
        }
        image2 {
          file {
            url
          }
        }
        title
        content {
          content
        }
        label
        url
        secondaryButtonUrl
        secondaryButtonLabel
      }
    }
  }
`
